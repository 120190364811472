<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Broadcast campaign</b> yang ada di {{ clinic_name }}
      </div>
      <b-button squared variant="success" @click="addData" v-if="btn">Tambah</b-button>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end mt-n3">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Jenis"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- Table -->
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm">
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '20%' : '' }" />
              </template>
              <template #cell(text_campaign)="data">
                <span>{{ data.item.text_campaign.split(' ').slice(0, 20).join(' '), }}</span>
              </template>
              <template #cell(sent_status)="data">
                <b-badge
                  pill
                  variant="warning"
                  v-if="data.item.sent_status == 0">Belum Terkirim</b-badge>
                <b-badge
                  pill
                  variant="success"
                  v-if="data.item.sent_status == 1">Terkirim</b-badge>
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="getData(data.item, 'Detail')"><i class="fas fa-eye px-0"></i></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="getData(data.item, 'Update')"
                  v-if="data.item.sent_status == 0 && btn"><i class="fas fa-edit px-0"></i></b-button>
                <b-button
                  v-if="data.item.sent_status == 0 && btn"
                  size="sm"
                  class="mr-1 btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
                <b-button
                  v-if="data.item.sent_status == 0"
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Kirim Campaign"
                  @click="showModalPreview(data.item)">
                  <i class="fab fa-whatsapp px-0"></i>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <!-- modal Add and update  -->
    <b-modal id="modal-broadcast-content" hide-footer size="lg">
      <template #modal-title>
        {{ form.purpose }} Konten Pesan Siaran (Broadcast Campaign)
      </template>

      <b-form @submit.stop.prevent="formOnSubmit">
        <span>Lampiran :</span>
        <div class="row justify-content-center mb-2">
          <!-- <div class="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-6"> -->
          <div class="col-md-12">
            <button
              class="btn btn-rounded btn-outline-purple btn-block btn-upload-csv"
              @click="showUploadFile"
              type="button">
              <div
                class="d-flex align-items-center justify-content-center h-100">
                <div class="w-100">
                  <h1 v-if="form.typeFile == ''">
                    <span class="fas fa-fw fa-lg fa-plus menu-icon"></span>
                  </h1>
                  <h1 v-if="form.typeFile != ''">
                    <span class="fas fa-fw fa-lg fa-file menu-icon"></span>
                    {{ form.attachment_name }}
                  </h1>
                  <small class="text-wrap" v-if="form.typeFile == ''">Upload file</small>
                </div>
              </div>
            </button>
          </div>
        </div>

        <!-- Input Description -->
        <b-form-group id="input-group-text_campaign">
          <label for="input-text_campaign">Isi Konten : </label>
          <b-form-textarea
            id="input-text_campaign"
            v-model="form.text_campaign"
            placeholder="Isi Konten"
            rows="4"
            max-rows="6"
            :disabled="form.purpose == 'Detail'"></b-form-textarea>
          <small class="text-danger">{{ error.text_campaign }}</small>
        </b-form-group>

        <!-- File Attachment -->
        <b-form-file
          placeholder="Upload Dokumen"
          drop-placeholder="Drop file here..."
          @change="upload"
          ref="file"
          style="visibility: hidden"></b-form-file>
        <small class="text-danger">{{ error.file }}</small>

        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            type="submit"
            class="mr-1"
            v-if="form.purpose != 'Detail'">
            Simpan
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="$bvModal.hide('modal-broadcast-content')"
            v-if="form.purpose != 'Detail'">
            Batal
          </b-button>
          <b-button
            type="button"
            variant="secondary"
            @click="
              $bvModal.hide('modal-broadcast-content'), (form.purpose = 'Add')
            "
            v-if="form.purpose == 'Detail'">
            Tutup
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // form
      form: {
        purpose: "Tambah",
        text_campaign: "",
        attachment: "",
        attachment_name: "",
        typeFile: "",
      },
      error: {
        text_campaign: "",
        attachment: "",
      },
      route: "/broadcast-campaigns",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "text_campaign",
          label: "Ringaksan teks",
          sortable: true,
        },
        {
          key: "sent_status",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // user access
      btn: false,
      clinic_name:
        window.configuration == null
          ? "Medhop"
          : window.configuration.clinic_name,
    };
  },

  methods: {
    showUploadFile() {
      if (this.form.purpose != "Detail") {
        this.$refs.file.$refs.input.click();
      }
    },

    addData() {
      this.form.purpose = "Tambah";
      this.form.text_campaign = "";
      this.form.attachment = "";
      this.form.attachment_name = "";
      this.form.typeFile = "";

      this.$bvModal.show("modal-broadcast-content");
    },

    pageOnClick(page) {
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginate(
        "broadcast-campaigns",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByName() {
      debouncer(() => { this.pagination() }, 500)
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("broadcast-campaigns/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },

    upload(event) {
      let itemsTable = event.target.files;
      console.log(itemsTable);

      this.form.typeFile = itemsTable[0].type;
      this.form.attachment_name = itemsTable[0].name;
      this.form.attachment = itemsTable[0];
    },

    async getData(item, purpose) {
      console.log(item);
      this.route = "/broadcast-campaigns/" + item.id;
      this.form = await module.get(this.route);
      this.form.purpose = purpose;

      if (this.form != null) {
        let file = this.form.attachment.split("#");
        file = file[1];

        this.form["_method"] = "put";
        this.form.attachment_name = file;
        this.form.attachment = "";

        this.$bvModal.show("modal-broadcast-content");
      }
    },

    async formOnSubmit() {
      let formData = new FormData();

      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        this.$bvModal.hide("modal-broadcast-content");
        this.pagination();
        this.form.purpose = "Add";
        Swal.fire(response.success.title, response.success.message, "success");
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Satuan" }]);
    // Get Data
    this.pagination();
    this.setActiveButton();
  },
};
</script>

<style scoped>
.btn-outline-purple {
  border: 1.28px solid !important;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.card-rounded {
  border: 1.28px solid !important;
  border-radius: 15px;
  border-color: #305b8f;
  color: #305b8f !important;
  transition: all ease-in-out 0.3s;
}

.btn-outline-purple:hover {
  border-color: #305b8f !important;
  background: #305b8f;
  color: white !important;
}

.btn-rounded {
  border-radius: 15px;
}
</style>

